import { useNavigate } from 'react-router-dom';
import React from 'react';
import SHOPIFY_CHECKOUT_URL from '../../constants/shopify';
import { Box } from '../../ui';
import { ActionButton } from './index';

type Props = {
  page?: string;
  variant?: 'primary' | 'secondary' | 'outline';
};

const BuyNowBtn = ({
  page = SHOPIFY_CHECKOUT_URL,
  variant = 'primary',
}: Props) => {
  const navigate = useNavigate();

  const getButtonStyles = () => {
    switch (variant) {
      case 'secondary':
        return {
          bg: 'transparent',
          _hover: {
            bg: '#E3DBD3',
            color: '#141414',
            textDecoration: 'none',
          },
          border: '2px solid #C6B6A6',
        };
      case 'outline':
        return {
          bg: 'transparent',
          color: '#E3DBD3',
          _hover: {
            bg: '#E3DBD3',
            color: '#141414',
            textDecoration: 'none',
          },
          border: '2px solid #C6B6A6',
        };
      default:
        return {};
    }
  };

  return (
    <Box>
      <ActionButton
        {...getButtonStyles()}
        onClick={() => {
          if (page.startsWith('/')) {
            navigate(page);
          } else {
            window.open(page, '_self');
          }
        }}
      >
        Subscribe Now
      </ActionButton>
    </Box>
  );
};

export default BuyNowBtn;
